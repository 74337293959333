export const GET_ENVIRONMENT = 'GET_ENVIRONMENT'
export const GET_ENVIRONMENT_SUCCESS = 'GET_ENVIRONMENT_SUCCESS'
export const GET_ENVIRONMENT_FAILURE = 'GET_ENVIRONMENT_FAILURE'

export const GET_NETWORK = 'GET_NETWORK'
export const GET_NETWORK_SUCCESS = 'GET_NETWORK_SUCCESS'
export const GET_NETWORK_FAILURE = 'GET_NETWORK_FAILURE'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'

export const GET_VM = 'GET_VM'
export const GET_VM_SUCCESS = 'GET_VM_SUCCESS'
export const GET_VM_FAILURE = 'GET_VM_FAILURE'

export const GET_VM_TEMPLATE = 'GET_VM_TEMPLATE'
export const GET_VM_TEMPLATE_SUCCESS = 'GET_VM_TEMPLATE_SUCCESS'
export const GET_VM_TEMPLATE_FAILURE = 'GET_VM_TEMPLATE_FAILURE'

export const CLEAR_ALERT = 'CLEAR_ALERT'
export const CLEAR_ALL_ALERTS = 'CLEAR_ALL_ALERTS'
export const ADD_ALERT = 'ADD_ALERT'
